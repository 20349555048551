import { ApiResponse, BaseHttpService, Media } from '@ems/api';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class MediaService extends BaseHttpService {

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getMedia(id: number): Observable<any> {
    const queryParams = {
      'loader': false,
      'expand[0]': 'mediaCategory',
      'expand[1]': 'publicationDay',
      'expand[2]': 'releaseSchedule',
      'expand[3]': 'badges',
      'expand[4]': 'mediumRating',
      'expand[5]': 'datasourceDistributionArea',
      'expand[6]': 'updatedBy',
      'expand[7]': 'company',
      'expand[8]': 'nextCompanyLogs.company',
    }
    return this.get('medium/' + id, queryParams);
  }

  getList(queryParams: any): Observable<any> {
    return this.get('media', queryParams);
  }

  updateMedia(id: number, body: any): Observable<ApiResponse<any>> {
    const queryParams = {
      'loader': false,
      'expand[0]': 'company.mailingAddress',
      'expand[1]': 'mediaCategory',
      'expand[2]': 'updatedBy'
    }
    return this.post('medium/' + id, body, {}, queryParams);
  }

  updateCompanyId(id: number, body: any): Observable<ApiResponse<any>> {
    return this.post('medium/' + id, body);
  }

  createMedia(body: any): Observable<ApiResponse<any>> {
    const queryParams = {
      'loader': false,
      'expand[0]': 'company.mailingAddress',
      'expand[1]': 'mediaCategory',
      'expand[2]': 'updatedBy'
    }
    return this.post('media', body, {}, queryParams);
  }

  deleteMedia(id: string | number): Observable<any> {
    const queryParams = {
      'expand[0]': 'updatedBy'
    }
    return this.delete('medium/' + id, queryParams);
  }

  getConditionsByMedium(additionalParams: Record<string, unknown> = {}): Observable<any> {
    const params = {
        ...additionalParams,
        'expand[0]': 'company',
        'expand[1]': 'advertisingType.advertisingCategory',
        'expand[2]': 'section',
      }
    return this.get('price-term', params);
  }

  getMediaByQuery(query: string, page = 1): Observable<ApiResponse<Media[]>> {
    const queryParams = {
      ...(query ? { query } : {}),
      'expand[0]': 'mediaCategory',
      'expand[1]': 'company.mailingAddress',
      page,
    };

    return this.get('media', queryParams);
  }

  getMediaByCompanyId(companyId?: string | number): Observable<Media[]> {
    let queryParams = {};
    if (companyId) {
      queryParams = {
        ...queryParams,
        'filter[company_id][eq]': companyId,
        'expand[]': 'mediaCategory'
      }
    }
    return this.getAll('media', queryParams);
  }

  createMediaHistory(body: any): Observable<ApiResponse<any>> {
    const queryParams = {
      'loader': false,
    }
    return this.post('company-medium-log', body, {}, queryParams);
  }

  createBatchMediaHistory(body: any, companyId?: string | number): Observable<ApiResponse<any>> {
    const queryParams = {
      'loader': false,
      'expand[2]': 'createdBy'
    }
    return this.post('company-medium/batch-create/' + companyId, body, {}, queryParams);
  }

  getMediaHistoryList(mediaId?: string | number, queryParams?: any): Observable<any> {
    const params = {
      ...queryParams,
      'filter[medium_id][eq]': mediaId,
      'expand[0]': 'company',
      'expand[1]': 'createdBy',
      'expand[2]': 'updatedBy',
      'sort': '-change_date'
    }
    return this.get('company-medium-log', params);
  }

  getPublicationDayIdByName(name?: string | number, queryParams?: any): Observable<ApiResponse<any>> {
    const params = {
      ...queryParams,
      ...( name ? { 'filter[name][eq]': name } : {}),
    }
    return this.get('publication-days', params);
  }

  getPublicationDayNameById(id?: string | number, queryParams?: any): Observable<ApiResponse<any>> {
    const params = {
      ...queryParams
    }
    return this.get('publication-days/' +  id, params);
  }

}
